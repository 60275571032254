import React, { useRef } from "react";
import styled from "styled-components";

import DokterItem from "./DokterItem";
import Pagination from "./Pagination";
import NotFound from "./NotFound";

const DokterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 25rem));
  justify-content: space-around;
  align-content: space-between;
  align-items: start;
  padding: 4rem 0;
  grid-gap: 4rem 2rem;

  @media ${props => props.theme.mediaQueries.small} {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 23rem));
    justify-content: space-around;
    grid-gap: 4rem 1.5rem;
  }

  @media ${props => props.theme.mediaQueries.smaller} {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 18rem));
    grid-gap: 4rem 1rem;
  }
`;

// Get Props data
const DokterList = ({ dokters }) => {
  if (dokters.result.length === 0) {
    return <NotFound />;
  }

  const Element = useRef();

  const scrollToMyRef = () => {
    window.scrollTo({
      top: (0, Element.current.offsetTop),
      behavior: "smooth"
    });
  };
  return (
    <React.Fragment>
      <DokterWrapper ref={Element}>
        {dokters.result.map(dokter => (
          <DokterItem dokter={dokter} key={dokter.id} />
        ))}
      </DokterWrapper>
      <Pagination dokters={dokters} scrollToMyRef={scrollToMyRef} />
    </React.Fragment>
  );
};

export default DokterList;
