import * as TYPES from "./types";
import backendAPI from "../api/website";
import history from "../history";

// First Init
export const init = () => async dispatch => {
  dispatch({ type: TYPES.SET_LOADING });
  await dispatch(getSpesialis());
  dispatch({ type: TYPES.REMOVE_LOADING });
};

// GET SPESIALIS
export const getSpesialis = () => async dispatch => {
  const res = await backendAPI.get("/spesialis");
  dispatch({
    type: TYPES.GET_SPESIALIS,
    payload: res.data
  });
};

// SET SELECTED SIDEBAR ITEM
export const setSelectedMenu = nama => (dispatch, getState) => {
  const { staticCategories, spesialis } = getState().geral;
  if (!nama) {
    dispatch({ type: TYPES.REMOVE_SELECTED_MENU });
  } else if (
    staticCategories.find(category => category === nama) ||
    spesialis.find(dokter => dokter.nama === nama)
  ) {
    dispatch({
      type: TYPES.SELECTED_MENU,
      payload: nama
    });
  } else {
    history.push(process.env.PUBLIC_URL + "/404");
  }
};

// Get Dokter Discover
export const getDoktersDiscover = (nama, halaman) => async (
  dispatch,
  getState
) => {
  const { selected } = getState().geral;
  if (!selected) {
    return;
  }
  try {
    dispatch({ type: TYPES.FETCH_DOKTERS_LOADING });
    const res = await backendAPI.get(`/all`, {
      params: {
        h: nama,
        g: "nama",
        p: halaman
      }
    });
    await dispatch({
      type: TYPES.FETCH_DOKTERS_DISCOVER,
      payload: res.data
    });
    dispatch({ type: TYPES.FETCH_DOKTERS_FINISHED });
  } catch (err) {
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    history.push(process.env.PUBLIC_URL + "/error");
  }
};

// Get Dokter Spesialis
export const getDoktersSpesial = (nama, halaman) => async (
  dispatch,
  getState
) => {
  const { selected, spesialis } = getState().geral;
  if (!selected) {
    return;
  }
  try {
    dispatch({ type: TYPES.FETCH_DOKTERS_LOADING });
    const nm = nama.replace(/%20/g, " ");
    const spesialId = spesialis
      .filter(el => el.nama === nm)
      .map(el => el.id)
      .join("");
    // source: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    const res = await backendAPI.get("/all", {
      params: {
        kk: spesialId,
        g: "nama",
        p: halaman
      }
    });
    await dispatch({
      type: TYPES.FETCH_DOKTERS_SPESIALIS,
      payload: res.data
    });
    dispatch({ type: TYPES.FETCH_DOKTERS_FINISHED });
  } catch (err) {
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    history.push(process.env.PUBLIC_URL + "/error");
  }
};

// Get Dokter Search
export const getDoktersSearch = (nama, halaman) => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_DOKTERS_LOADING });
    // source: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    const res = await backendAPI.get("/all", {
      params: {
        g: "nama",
        d: nama,
        p: halaman
      }
    });
    await dispatch({
      type: TYPES.FETCH_DOKTERS_SEARCH,
      payload: res.data
    });
    dispatch({ type: TYPES.FETCH_DOKTERS_FINISHED });
  } catch (err) {
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    history.push(process.env.PUBLIC_URL + "/error");
  }
};

// Get Single Dokter
export const getDokter = id => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_DOKTER_LOADING });
    const res = await backendAPI.get(`/detail`, {
      params: {
        g: "nama",
        s: id
      }
    });
    await dispatch({
      type: TYPES.FETCH_DOKTER,
      payload: res.data
    });
    dispatch({ type: TYPES.FETCH_DOKTER_FINISHED });
  } catch (err) {
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    history.push(process.env.PUBLIC_URL + "/error");
  }
};

// Get Rekomendasi Dokter
export const getRekomendasi = (id, halaman) => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_REKOMENDASI_LOADING });
    const res = await backendAPI.get("/rekomendasi", {
      params: {
        s: id,
        g: "nama",
        p: halaman
      }
    });
    await dispatch({
      type: TYPES.FETCH_REKOMENDASI,
      payload: res.data
    });
    dispatch({ type: TYPES.FETCH_REKOMENDASI_FINISHED });
  } catch (err) {
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    history.push(process.env.PUBLIC_URL + "/error");
  }
};

// Get Rekomendasi Dokter
export const getJadwal = id => async dispatch => {
  try {
    dispatch({ type: TYPES.FETCH_JADWAL_LOADING });
    const res = await backendAPI.get("/jadwal", {
      params: {
        s: id
      }
    });
    await dispatch({
      type: TYPES.FETCH_JADWAL,
      payload: res.data
    });
    dispatch({ type: TYPES.FETCH_JADWAL_FINISHED });
  } catch (err) {
    dispatch({ type: TYPES.INSERT_ERROR, payload: err.response });
    history.push(process.env.PUBLIC_URL + "/error");
  }
};

// Set loading to true for next render
export const clearJadwal = () => {
  return {
    type: TYPES.FETCH_JADWAL_LOADING
  };
};

// Set loading to true for next render
export const clearDokter = () => {
  return {
    type: TYPES.FETCH_DOKTER_LOADING
  };
};

// Set loading to true for next render
export const clearRekomendasi = () => {
  return {
    type: TYPES.FETCH_REKOMENDASI_LOADING
  };
};

export const clearError = () => ({ type: TYPES.CLEAR_ERROR });
