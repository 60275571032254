import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import queryString from "query-string";

import { setSelectedMenu, getDoktersSpesial, clearDokter } from "../actions";
import DokterList from "../components/DokterList";
import Loader from "../components/Loader";
import Header from "../components/Header";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Spesial = ({
  geral,
  match,
  setSelectedMenu,
  getDoktersSpesial,
  clearDokter,
  dokters,
  location
}) => {
  const params = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
  }, []);

  // Send url to setSelected Action Creator, it will check if is valid, and set the header accordingly
  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
    setSelectedMenu(match.params.nama);
    // clear state
    return () => setSelectedMenu();
  }, [match.params.nama]);

  // call hook to fetch dokter spesialis
  useFetchDoktersSpesial(
    match.params.nama,
    getDoktersSpesial,
    params,
    clearDokter
  );

  //If there are no movies, still fetching, loading
  if (dokters.loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Dokter ${geral.selected}`}</title>
      </Helmet>
      <Header title={geral.selected} subtitle="Dokter" />
      <DokterList dokters={dokters} />
    </Wrapper>
  );
};

function useFetchDoktersSpesial(
  spesial,
  getDoktersSpesial,
  params,
  clearDokter
) {
  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
    getDoktersSpesial(spesial, params.p);
    return () => clearDokter();
  }, [spesial, params.p]);
}

// Map State to Component Props
const mapStateToProps = ({ geral, dokters }) => {
  return { geral, dokters };
};

export default connect(mapStateToProps, {
  setSelectedMenu,
  getDoktersSpesial,
  clearDokter
})(Spesial);
