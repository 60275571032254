import * as TYPES from "../actions/types";

const INITIAL_STATE = {
  staticCategories: ["Hari Ini", "#"],
  loading: true
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_SPESIALIS:
      return { ...state, ...action.payload };
    case TYPES.SELECTED_MENU:
      return { ...state, selected: action.payload };
    case TYPES.REMOVE_SELECTED_MENU:
      return { ...state, selected: null };
    case TYPES.SET_LOADING:
      return { ...state, loading: true };
    case TYPES.REMOVE_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
};
