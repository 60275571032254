import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import NothingSvg from "../svg/nothing.svg";
import Loading from "../components/Loading";

const DokterWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: transparent;
  border-radius: 0.8rem;
  position: relative;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    transform: scale(1.03);

    ::after {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.8rem;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    background-color: var(--color-primary);
    z-index: -99;
    box-shadow: 0rem 2rem 5rem var(--shadow-color-dark);
    transition: all 100ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const ImgLoad = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
  border-radius: 0.8rem;
  box-shadow: 0rem 2rem 5rem var(--shadow-color);
  transition: all 100ms cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const DokterImg = styled.img`
  width: 100%;
  height: 38rem;
  object-fit: cover;
  border-radius: 0.8rem;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0rem 2rem 5rem var(--shadow-color);

  ${DokterWrapper}:hover & {
    border-radius: 0.8rem 0.8rem 0rem 0rem;
    box-shadow: none;
  }

  @media ${props => props.theme.mediaQueries.smaller} {
    height: 28rem;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.2em;
  font-weight: 400;
  color: var(--color-primary-light);
  margin-bottom: 1rem;
  line-height: 1.4;

  ${DokterWrapper}:hover & {
    color: var(--text-color);
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem; 3rem;

  @media ${props => props.theme.mediaQueries.smaller} {
    padding: 1.5rem 1.5rem;
  }
  
`;

const KlinikWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 0.5rem;
  color: var(--color-primary);

  ${DokterWrapper}:hover & {
    color: var(--color-primary-lighter);
  }
`;

const DokterItem = ({ dokter }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    return () => setLoaded(false);
  }, []);

  let dok = dokter.nama_poli;

  const url =
  process.env.NODE_ENV === "development"
    ? `http://192.168.132.2/foto/`
    : `https://simrspersahabatan.co.id/foto/`;

  return (
    <LazyLoad height={200} offset={200}>
      <DokterWrapper
        loaded={loaded ? 1 : 0}
        to={`${process.env.PUBLIC_URL}/dokter/${dokter.id}`}
        data-umami-event={`dokter--${dokter.nama_dokter.replace(/ +/g, '-').replace(/,/g, '-').substring(3,25)}`}
      >
        {!loaded ? (
          <ImgLoad>
            <Loading />
          </ImgLoad>
        ) : null}
        <DokterImg
          error={error ? 1 : 0}
          onLoad={() => setLoaded(true)}
          style={!loaded ? { display: "none" } : {}}
          src={`${url}full/${dokter.foto_en}`}
          // Jika tidak ada Foto
          onError={e => {
            setError(true);
            if (e.target.src !== `${NothingSvg}`) {
              e.target.src = `${NothingSvg}`;
            }
          }}
        />

        <DetailsWrapper>
          <Title>{dokter.nama_dokter}</Title>
          <KlinikWrapper>{dok}</KlinikWrapper>
        </DetailsWrapper>
      </DokterWrapper>
    </LazyLoad>
  );
};

export default DokterItem;
