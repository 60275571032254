import { combineReducers } from "redux";
import configReducer from "./configReducer";
import doktersReducer from "./doktersReducer";
import dokterReducer from "./dokterReducer";
import rekomendasiReducer from "./rekomendasiReducer";
import errorReducer from "./errorReducer";
import jadwalReducer from "./jadwalReducer";

export default combineReducers({
  geral: configReducer, // spesialis (array)
  dokters: doktersReducer, // all dokter (array)
  dokter: dokterReducer, // single dokter (object)
  rekomendasi: rekomendasiReducer, // rekomendasi dokter (array)
  error: errorReducer, // error redus,
  jadwal: jadwalReducer // jadwal
});
