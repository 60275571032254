import React from "react";
import styled from "styled-components";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import NotFound from "./NotFound";

const TableWrapper = styled(Table)`
  border-collapse: collapse;
  background: rgba(255, 255, 255, 0.4);
`;

const TrWrapper = styled(Tr)`
  border: 1px solid #000;
`;

const ThWrapper = styled(Th)`
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: 1px solid #000;
  padding: 0.8rem;
  text-align: center;
  vertical-align: bottom;
`;

const TdWrapper = styled(Td)`
  vertical-align: text-top;
  padding: 1rem;
  vertical-align: top;
  text-align: center;
`;

const ShowOnHoverWrapper = styled.span`
  position: absolute;
  left: 69%;
  padding: 2px 8px;
  border-radius: 5px;
  pointer-events: none;
  background-color: var(--color-primary-lighter);
  opacity: 0;
  &:hover {
    opacity: 1;
  }

  @media ${props => props.theme.mediaQueries.smaller} {
    left: 50%;
  }

  @media ${props => props.theme.mediaQueries.small} {
    left: 65%;
  }

  @media ${props => props.theme.mediaQueries.large} {
    left: 50%;
  }

  @media ${props => props.theme.mediaQueries.larger} {
    left: 77%;
  }

`
const LinkBooking = styled.a`
  text-decoration: none;
  font-weight: 500;
  margin-left: 4px;
  color: inherit;
  &:hover ~ ${ShowOnHoverWrapper} {
    opacity: 1;
  }
`

// Get Props data
const JadwalList = ({ jadwals, namaKlinik }) => { //console.log(jadwals)
  if (jadwals.result.length === 0) {
    return <NotFound />;
  }
  return (
    <React.Fragment>
      <TableWrapper>
        <Thead>
          <TrWrapper>
            <ThWrapper>Hari</ThWrapper>
            <ThWrapper>Klinik</ThWrapper>
            <ThWrapper>Waktu</ThWrapper>
          </TrWrapper>
        </Thead>
        <Tbody>
          {jadwals.result.map(jadwal => 
            {
              return (jadwal.klinik === namaKlinik) ? (
                <TrWrapper key={jadwal.id.toString()}>
                  <TdWrapper>{jadwal.hari}</TdWrapper>
                  <TdWrapper>{jadwal.klinik}</TdWrapper>
                  <TdWrapper><LinkBooking href={`https://rsuppersahabatan.co.id/pendaftaran/index.php/halaman/${jadwal.en_url}`}>{jadwal.jam}</LinkBooking> <ShowOnHoverWrapper>BUAT PERJANJIAN</ShowOnHoverWrapper></TdWrapper>
                </TrWrapper>
              ) : (
                <TrWrapper key={jadwal.id.toString()}></TrWrapper>
              );
            }
          )}
        </Tbody>
      </TableWrapper>
    </React.Fragment>
  );
};

export default JadwalList;
