import React from "react";
import styled from "styled-components";
import Button from "./Button";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => {
    if (props.type === "one") {
      return "flex-start";
    } else if (props.type === "both") {
      return "space-between";
    } else {
      return "flex-end";
    }
  }};
`;

const WrapperLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 2rem;
`;

const Pagination = ({ dokters, scrollToMyRef }) => {
  const { halaman, jumlah } = dokters;

  if (jumlah === 1) {
    return null;
  }

  // halaman pertama, render halaman 2 tombol
  if (halaman < jumlah && halaman === 1) {
    return (
      <Wrapper>
        <WrapperLink
          to={`${process.env.PUBLIC_URL}?p=${halaman + 1}`}
          onClick={() => scrollToMyRef()}
        >
          <Button solid title={halaman + 1} icon="arrow-right" />
        </WrapperLink>
      </Wrapper>
    );
  }

  // Ada halaman selanjutnya dan sebelumnya, render bersamaan
  else if (halaman < jumlah) {
    return (
      <Wrapper type="both">
        <WrapperLink
          to={`${process.env.PUBLIC_URL}?p=${halaman - 1}`}
          onClick={() => scrollToMyRef()}
        >
          <Button
            solid
            left
            title={`Halman ${halaman - 1}`}
            icon="arrow-left"
          />
        </WrapperLink>
        <WrapperLink
          to={`${process.env.PUBLIC_URL}?p=${halaman + 1}`}
          onClick={() => scrollToMyRef()}
        >
          <Button solid title={`Halaman ${halaman + 1}`} icon="arrow-right" />
        </WrapperLink>
      </Wrapper>
    );
  }

  // Lainnya
  else {
    return (
      <Wrapper type="one">
        <WrapperLink
          to={`${process.env.PUBLIC_URL}?p=${halaman - 1}`}
          onClick={() => scrollToMyRef()}
        >
          <Button
            solid
            left
            title={`Halaman ${halaman - 1}`}
            icon="arrow-left"
          />
        </WrapperLink>
      </Wrapper>
    );
  }
};

export default Pagination;
