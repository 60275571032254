import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import queryString from "query-string";

import { setSelectedMenu, getDoktersDiscover, clearDokter } from "../actions";
import DokterList from "../components/DokterList";
import Loader from "../components/Loader";
import Header from "../components/Header";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Discover = ({
  geral,
  match,
  location,
  setSelectedMenu,
  getDoktersDiscover,
  clearDokter,
  dokters
}) => {
  const params = queryString.parse(location.search);
  // console.log(dokters);

  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
  }, []);

  // seend url setSelected action creator, check if valid
  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
    setSelectedMenu(match.params.nama);
    // clean state selected
    return () => setSelectedMenu();
  }, [match.params.nama]);

  useFetchDoktersDiscover(
    match.params.nama,
    getDoktersDiscover,
    params,
    clearDokter
  );

  if (dokters.loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf8" />
        <title>{`Dokter ${geral.selected}`}</title>
      </Helmet>
      <Header title={geral.selected} subtitle="Dokter" />
      <DokterList dokters={dokters} />
    </Wrapper>
  );
};

function useFetchDoktersDiscover(
  nama,
  getDoktersDiscover,
  params,
  clearDokter
) {
  const query = nama.replace(/\s+/g, "_").toLowerCase();
  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
    getDoktersDiscover(query, params.p);
    return () => clearDokter();
  }, [query, params.p]);
}

const mapStateToProps = ({ geral, dokters }) => {
  return { geral, dokters };
};

export default connect(mapStateToProps, {
  setSelectedMenu,
  getDoktersDiscover,
  clearDokter
})(Discover);
