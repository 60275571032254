import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import App from "./containers/App";

// Redux
import reducers from "./reducers";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";

// Themes
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./helper/globals";
import theme from "./helper/theme";

/* https://github.com/reduxjs/redux-thunk/issues/35#issuecomment-184903773 */

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dokter RSUP Persahabatan</title>
          <meta
            name="description"
            content="Pencarian dokter RSUP Persahabatan Jakarta Timur"
          />
          <link rel="canonical" href="https://dokter.rsuppersahabatan.co.id" />
        </Helmet>
        <App />
        <GlobalStyle />
      </Fragment>
    </ThemeProvider>
  </Provider>,
  document.querySelector("#root")
);
