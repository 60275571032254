import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import queryString from "query-string";

import { getDoktersSearch, clearDokter } from "../actions";
import DokterList from "../components/DokterList";
import Loader from "../components/Loader";
import Header from "../components/Header";
import NotFound from "../components/NotFound";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Search = ({
  match,
  getDoktersSearch,
  clearDokter,
  dokters,
  location
}) => {
  const { query } = match.params;
  const params = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
  }, []);

  // call hook to fetch dokter Searchis
  useFetchDoktersSearch(query, getDoktersSearch, params, clearDokter);

  //If there are no movies, still fetching, loading
  if (dokters.loading) {
    return <Loader />;
  } else if (dokters.jumlah === 0) {
    return (
      <NotFound title="Maaf!" subtitle={`Hasil Pencarian untuk: ${query}`} />
    );
  } else {
    return (
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Pencarian Dokter: ${query}`}</title>
        </Helmet>
        <Header title={query} subtitle="Hasil Pencarian Dokter" />
        <DokterList dokters={dokters} />
      </Wrapper>
    );
  }
};

function useFetchDoktersSearch(query, getDoktersSearch, params, clearDokter) {
  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
    getDoktersSearch(query, params.p);
    return () => clearDokter();
  }, [query, params.p]);
}

// Map State to Component Props
const mapStateToProps = ({ dokters }) => {
  return {
    dokters
  };
};

export default connect(mapStateToProps, {
  getDoktersSearch,
  clearDokter
})(Search);
