export const GET_SPESIALIS = "GET_SPESIALIS";

// Menu
export const SELECTED_MENU = "SELECTED_MENU";
export const REMOVE_SELECTED_MENU = "REMOVE_SELECTED_MENU";

// Fetch
export const FETCH_DOKTERS_DISCOVER = "FETCH_DOKTERS_DISCOVER";
export const FETCH_DOKTERS_SPESIALIS = "FETCH_DOKTERS_SPESIALIS";
export const FETCH_DOKTERS_SEARCH = "FETCH_DOKTERS_SEARCH";
export const FETCH_DOKTER = "FETCH_DOKTER";
export const FETCH_REKOMENDASI = "FETCH_REKOMENDASI";
export const FETCH_JADWAL = "FETCH_JADWAL";

// Loading
export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";

// loading Dokter All
export const FETCH_DOKTERS_LOADING = "FETCH_DOKTERS_LOADING";
export const FETCH_DOKTERS_FINISHED = "FETCH_DOKTERS_FINISHED";

// loading Dokter Detail
export const FETCH_DOKTER_LOADING = "FETCH_DOKTER_LOADING";
export const FETCH_DOKTER_FINISHED = "FETCH_DOKTER_FINISHED";

// loading Dokter Rekomendasi
export const FETCH_REKOMENDASI_LOADING = "FETCH_REKOMENDASI_LOADING";
export const FETCH_REKOMENDASI_FINISHED = "FETCH_REKOMENDASI_FINISHED";

// Loading Jadwal
export const FETCH_JADWAL_LOADING = "FETCH_JADWAL_LOADING";
export const FETCH_JADWAL_FINISHED = "FETCH_JADWAL_FINISHED";

// Error
export const INSERT_ERROR = "INSERT_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
