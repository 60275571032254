import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import history from "../history";
import { init } from "../actions";

// Komponen
import SearchBar from "../components/SearchBar";
import NotFound from "../components/NotFound";
import Loader from "../components/Loader";
import ShowError from "../components/ShowError";

// Kontainer
import Sidebar from "./Sidebar";
import Spesial from "./Spesial";
import Discover from "./Discover";
import Search from "./Search";
import Dokter from "./Dokter";

// Helper
import MenuMobile from "../helper/MenuMobile";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowRight,
  faHome,
  faCalendar,
  faPoll,
  faHeart,
  faDotCircle,
  faStar as fasFaStar,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import { faStar as farFaStar } from "@fortawesome/free-regular-svg-icons";

library.add(
  faArrowLeft,
  faArrowRight,
  faHome,
  faCalendar,
  faPoll,
  faHeart,
  faDotCircle,
  fasFaStar,
  farFaStar,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faLink
);

const MainWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isMobile ? "column" : "row")};
  align-items: flex-start;
  height: 100%;
  width: 100%;
  user-select: none;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 2rem;

  @media ${props => props.theme.mediaQueries.larger} {
    padding: 6rem 3rem;
  }

  @media ${props => props.theme.mediaQueries.large} {
    padding: 4rem 2rem;
  }
`;

const SearchBarWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2rem;
`;

const App = ({ init, isLoading }) => {
  useEffect(() => {
    init();
  }, []);

  const [isMobile, setisMobile] = useState(null);

  // tentukan jumlah item yang muncul berdasaran slider element
  const changeMobile = () => {
    window.matchMedia("(max-width: 80em)").matches
      ? setisMobile(true)
      : setisMobile(false);
  };

  useEffect(() => {
    changeMobile();
    window.addEventListener("resize", changeMobile);
    return () => window.removeEventListener("resize", changeMobile);
  }, []);

  return isLoading ? (
    <ContentWrapper>
      <Loader />
    </ContentWrapper>
  ) : (
    <Router history={history}>
      <React.Fragment>
        <MainWrapper isMobile={isMobile}>
          {isMobile ? (
            <MenuMobile />
          ) : (
            <>
              <Sidebar />
              <SearchBarWrapper>
                <SearchBar />
              </SearchBarWrapper>
            </>
          )}
          <ContentWrapper>
            <Switch>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                exact
                render={() => (
                  <Redirect
                    from={process.env.PUBLIC_URL + "/"}
                    to={process.env.PUBLIC_URL + "/discover/Hari Ini"}
                  />
                )}
              />
              <Route
                path={process.env.PUBLIC_URL + "/spesialis/:nama"}
                exact
                component={Spesial}
              />
              <Route
                path={process.env.PUBLIC_URL + "/discover/:nama"}
                exact
                component={Discover}
              />
              <Route
                path={process.env.PUBLIC_URL + "/search/:query"}
                exact
                component={Search}
              />
              <Route
                path={process.env.PUBLIC_URL + "/dokter/:id"}
                exact
                component={Dokter}
              />
              <Route
                path={process.env.PUBLIC_URL + "/404"}
                component={() => (
                  <NotFound title="Upps!" subtitle={`Halaman tidak ada...`} />
                )}
              />
              <Route
                path={process.env.PUBLIC_URL + "/error"}
                component={ShowError}
              />
              <Route
                component={() => (
                  <NotFound title="Upps!" subtitle={`Halaman tidak ada...`} />
                )}
              />
            </Switch>
          </ContentWrapper>
        </MainWrapper>
      </React.Fragment>
    </Router>
  );
};

const mapStateToProps = ({ geral }) => {
  return { isLoading: geral.loading };
};

export default connect(mapStateToProps, { init })(App);
